@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

// General styling for the header
.header {
  position: relative;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-text {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5); /* Subtle background behind the text */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Adds depth to the text */
  }

  .header-image {
    background-image: url('../../../public/images/hs-logo.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    filter: brightness(40%);
    border-radius: 5px;
  }

  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: rgba(
      0,
      0,
      0,
      0.4
    ); /* Adds an overlay for better text contrast */
    border-radius: 5px;
  }
}

// Styling for the exercise details section
.exercise-details {
  padding: 20px;
  background-color: #f8f9fa;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      margin-bottom: 8px;
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .header-text {
    font-size: 2rem;
    padding: 10px;
  }
}
