body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin-top: 60px;
  padding: 0;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  background-color: #fff;
}
th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
th {
  background-color: #4caf50;
  color: white;
}
tr:hover {
  background-color: #f1f1f1;
}
.center {
  text-align: center;
}
