$primary-color: #3498db;

.custom-button {
  background-color: $primary-color;
  color: white;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.custom-text {
  font-size: 18px;
  color: $primary-color;
}
