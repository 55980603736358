/* Custom CSS to remove margin and padding from Accordion */
.accordion-no-margin {
  margin: 2px !important; /* Remove margin around accordion */
  padding: 2px !important; /* Remove padding around accordion */
}

.accordion-no-margin .accordion-item {
  // margin: 0 !important; /* Remove margin around each accordion item */
  // padding: 0 !important; /* Remove padding inside each accordion item */
}
